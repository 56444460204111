<template>
  <ConfirmDialog
    :value.sync="show"
    maxWidth="800px"
    title="Rejestr zmian"
    positiveButtonText=""
    :loading="loading"
    :compact="false"
    negativeButtonColor="primary"
    showCloseBtn
  >
    <v-layout column align-center justify-start mb-2 slot="header">
      <DefaultLabel>{{ name }}</DefaultLabel>
    </v-layout>

    <v-layout column slot="content">
      <v-layout class="popup-content" column>
        <v-layout
          class="mx-4"
          column
          v-if="records != undefined && records.length > 0"
        >
          <v-layout column v-for="record in records" :key="record.card.id">
            <v-layout>
              <v-layout column justify-center grow>
                <div class="zg-hfdr-line"></div>
              </v-layout>
              <v-layout shrink class="mx-2">
                <div class="black--text">
                  {{ `Wizyta ${record.card.order} - ${record.card.date_time}` }}
                </div>
              </v-layout>
              <v-layout column justify-center grow>
                <div class="zg-hfdr-line"></div>
              </v-layout>
            </v-layout>
            <FieldWrapperDelegate
              v-for="(recField, index) in record.fields"
              :key="recField.id"
              :field="recField"
              :class="['my-2', index != 0 && 'ml-12']"
              hideHeader
              hideEdit
              disabled
              clientOnlyPredefined
              forceVisible
            ></FieldWrapperDelegate>
          </v-layout>
        </v-layout>
        <v-layout
          v-if="records != undefined && records.length == 0 && !loading"
          class="text-center"
          align-center
          justify-center
        >
          <div class="text-subtitle-2 error--text font-weight-bold">
            Brak wpisów
          </div>
        </v-layout>
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import PatientService from "@/services/patient.service";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    field: {},
    patient: {},
    session: {},
    owner: {},
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      records: undefined,
      totalItems: 0,
      pagination: {
        rowsPerPage: 5,
        page: 1,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    FieldWrapperDelegate: () =>
      import("@/components/delegates/patientcard/FieldWrapperDelegate"),
  },
  methods: {
    fetchData() {
      this.beforeRequest();
      PatientService.getHistoryField({
        name: this.field.name,
        session: this.session.id,
        options: this.pagination,
        patient: this.patient ? this.patient.id : undefined,
        owner: this.owner ? this.owner.id : undefined,
      })
        .then((reply) => {
          this.records = reply;
          this.reset();
        })
        .catch((x) => this.handleError(x));
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped lang="css">
.popup-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.zg-hfdr-line {
  background-color: var(--v-primary-base);
  height: 2px;
  width: 100%;
}
</style>